<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <v-container
      fill-height
      fluid
    >
      <loading
        :active.sync="requestLoading"
        :is-full-page="false"
      />
      <v-server-table
        class="pt-0"
        ref="table"
        name="company/companyTable"
        :columns="columns"
        :options="options"
      >
        <div slot="afterFilterWrapper">
          <v-card-title class="float-left pa-0">
            <v-app-bar
              flat
              class="white pa-0"
              elevate-on-scroll
              dense
            >
              <v-toolbar-title
                data-cy="company_listing_title"
              >
                {{ $t('company.listing.title') }}
              </v-toolbar-title>
              <v-spacer />
              <v-btn
                text
                color="primary"
                :to="{ name: 'CompanyCreate' }"
                data-cy="to_CompanyCreate_add_btn"
              >
                <v-icon left>
                  mdi-plus-outline
                </v-icon>
                {{ $t('company.listing.buttons.add') }}
              </v-btn>
            </v-app-bar>
          </v-card-title>
        </div>
        <div
          slot="ref_id"
          slot-scope="props"
        >
          {{ props.row.ref_id }}
        </div>
        <div
          slot="names"
          slot-scope="props"
        >
          <div>
            <b>{{ props.row | company_name(1) }}</b>
          </div>
          <div><small>{{ props.row | company_name(2) }}</small></div>
        </div>
        <div
          slot="directors"
          slot-scope="props"
        >
          <div
            v-for="(director, key) in props.row.directors"
            :key="key"
          >
            <span v-if="director.person">{{ director.person | natural_person_name }}</span>
            <span v-else-if="director.company">{{ director.company | company_name }}</span>
          </div>
        </div>
        <div
          slot="status"
          slot-scope="props"
        >
          <!-- {{ props.row.status == 1 ? 'Approved' : (props.row.status == 2 ? 'Rejected' : 'Pending') }} -->
          {{ $t(`company.details.form.status.options.${props.row.status == 1 ? 'active' : 'inactive'}`) }}
        </div>
        <div
          slot="batch_scan_date"
          slot-scope="props"
        >
          <DateFormat
            :date="props.row.batch_scan_date"
            :showtime="true"
          />
        </div>
        <div
          slot="br_number"
          slot-scope="props"
        >
          {{ props.row.br_number }}
        </div>
        <div
          slot="acc_end_date"
          slot-scope="props"
        >
          <DateFormat :date="props.row.acc_end_date" />
        </div>
        <div
          slot="create_date"
          slot-scope="props"
        >
          <DateFormat
            :date="props.row.create_date"
            :showtime="true"
          />
        </div>
        <div
          slot="updated_date"
          slot-scope="props"
        >
          <DateFormat
            :date="props.row.updated_date"
            :showtime="true"
          />
        </div>
        <div
          slot="action"
          slot-scope="props"
        >
          <v-btn
            icon
            class="mr-1"
            :to="{ name: 'CompanyEdit', params: { customer_id: props.row.id } }"
            :data-cy="`to_CompanyEdit_btn_${props.row.id}`"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <PopupDelete
            v-slot:default="{ on, loading }"
            @yes="(done) => {
              $store.dispatch('company/remove', props.row.id)
                .then(() => {
                  $refs.table.getData();
                })
                .catch((error) => { console.warn(error) })
                .finally(done); // notify popup stop loading and close
            }"
            data-cy="company"
          >
            <v-btn
              icon
              class="mr-1"
              color="error"
              v-on="on"
              :loading="loading"
              :data-cy="`delete_company_btn_${props.row.id}`"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </PopupDelete>
        </div>
      </v-server-table>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DateFormat from '@/components/common/DateFormat'
import PopupDelete from '@/components/popups/PopupDeleteConfirmation'

export default {
  components: {
    DateFormat,
    PopupDelete
  },
  computed: {
    ...mapGetters('company', [
      'options'
    ]),
    ...mapState('company', {
      requestLoading: state => state.listing.requestLoading,
      columns: state => state.listing.columns,
      dateColumns: state => state.listing.dateColumns
    })
  },
  data: () => {
    return {
    }
  }
  // methods: {

  // },
  // mounted () {

  // }
}
</script>

<style>
div#example1_paginate, div#example1_filter {
	text-align: right;
}
button.btn-sm.btn-block {
	width: 80px;
	float: left;
	margin: 0 5px;
}
</style>
